<template>
    <section class="container-wrap">
        <div class="container flex">
            <d-nav-content :dNavContentItems="navContentItems" :dNavContentItemsId="navContentItemsId"></d-nav-content>
            <div class="styled">
                <d-title d-level="1">Getting Started With DReadMore</d-title>

                <d-title :id="navContentItemsId[0]">{{ navContentItems[0] }}</d-title>
                <p>There are few options on how to include/import DReadMore into your project:</p>

                <d-title :id="navContentItemsId[1][0]" d-level="3">{{ navContentItems[1][0] }}</d-title>
                <p>We can install DReadMore from NPM</p>
                <highlightjs language="js"
                             code="$ npm install dreadmore" />
                <highlightjs language="js"
                             code="import DReadMore from 'dreadmore';

const dreadmore = new DReadMore();" />

                <d-title :id="navContentItemsId[1][1]" d-level="3">{{ navContentItems[1][1] }}</d-title>
                <p>We can use DReadMore from CDN</p>
                <highlightjs language="js"
                             code='<script src="https://unpkg.com/dreadmore@2/dreadmore.min.js"></script>' />

                <d-title :id="navContentItemsId[1][2]" d-level="3">{{ navContentItems[1][2] }}</d-title>
                <p>If you want to use DReadMore assets locally, you can directly download them from
                    <d-btn href="https://unpkg.com/dreadmore@2"
                           d-style="link">https://unpkg.com/dreadmore@2</d-btn>
                </p>

                <d-title :id="navContentItemsId[2]">{{ navContentItems[2] }}</d-title>
                <p>We need to add basic DReadMore layout:</p>
                <highlightjs language="xml"
                             code='<div class="dreadmore">
    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Impedit recusandae quas eaque
    laudantiumquo, dolorem vitae quia cupiditate sit, exercitationem suscipit molestiae iste
    dolores eos facere mollitia voluptatibus nihil. Dicta ex blanditiis officiis beatae
    similique neque nostrum consequatur maiores quas!
</div>
<button type="button"
        data-drm-toggler>Show more</button>' />

                <d-title :id="navContentItemsId[3]">{{ navContentItems[3] }}</d-title>
                <p>We need to add basic DReadMore css:</p>
                <highlightjs language="css"
                             code=".dreadmore {
    min-height: 2px;
    overflow: hidden;
}

/* for a disabled toggler */
.dreadmore--disabled {
    display: none;
}" />
                <p><b>1px min-height</b> = <b>1 visible line</b>.</p>
                <d-title :id="navContentItemsId[4]">{{ navContentItems[4] }}</d-title>
                <p>Finally, we need to initialize DReadMore in JS:</p>
                <highlightjs language="js"
                             code="const dreadmore = new DReadMore();" />

                <d-title :id="navContentItemsId[5]">{{ navContentItems[5] }}</d-title>
                <ul>
                    <li>Go to
                        <router-link to="documentation"
                                     v-slot="{ navigate, href }"
                                     custom>
                            <d-btn d-style="link"
                                   :href="href"
                                   @click="navigate">Documentation</d-btn>
                        </router-link>
                        to learn more about DReadMore.
                    </li>
                    <!-- <li>Look at available
                        <router-link to="demos"
                                     v-slot="{ navigate, href }"
                                     custom>
                            <d-btn d-style="link"
                                   :href="href"
                                   @click="navigate">Demos</d-btn>
                        </router-link>.</li> -->
                    <li>If you have questions about DReadMore ask them on
                        <d-btn d-style="link"
                               href="https://stackoverflow.com">StackOverflow</d-btn>
                        or
                        <d-btn d-style="link"
                               href="https://github.com/DSekon/DReadMore/discussions">DReadMore Discussions</d-btn>.</li>
                </ul>
            </div>
        </div>
    </section>
</template>

<script setup>
    import DTitle from '@/DTitle/DTitle'
    import DBtn from '@/DBtn/DBtn'
    import DNavContent from '^/DNavContent/DNavContent'

    const navContentItems = ['Installation', ['Install from NPM', 'Use DReadMore from CDN', 'Download assets'], 'Add DReadMore HTML Layout', 'Add CSS Styles', 'Initialize DReadMore', 'What\'s next?']
    const navContentItemsId = []

    function stringToId(string) {
        return string.toLowerCase().replaceAll('?', '').replaceAll(' ', '-')
    }

    navContentItems.forEach(function(item) {
        if (!Array.isArray(item)) {
            navContentItemsId.push(stringToId(item));
        } else {
            let array = [];

            item.forEach(function(itemInner) {
                array.push(stringToId(itemInner))
            })

            navContentItemsId.push(array);
        }
    })
</script>
