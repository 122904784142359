<template>
    <component :is="!href ? 'button' : 'a'"
               class="btn"
               :class="styledClasses"
               :type="!href ? 'button' : null"
               v-ripple="!['link', 'link-2', 'none'].includes(props.dStyle) ? true : false">
        <d-icon v-if="props.dIconName"
                class="btn__icon"
                :d-name="props.dIconName"
                :d-class="props.dIconClass" />
        <span class="btn__value">
            <slot />
        </span>
    </component>
</template>

<script setup>
    import {
        useAttrs
    } from 'vue'
    import dIcon from '@/DIcon/DIcon'

    const props = defineProps({
        dStyle: {
            type: String,
            default: 'bg'
        },
        dIconName: {
            type: [String, Boolean],
            default: false
        },
        dIconClass: {
            type: [String, Boolean],
            default: false
        }
    })
    const {
        href
    } = useAttrs()

    const styledClasses = [
        `btn--style_${props.dStyle}`
    ]
</script>

<style lang="scss">
    @import './style.scss';
</style>
