<template>
    <div class="nav-content-wrap">
        <nav class="nav-content">
            <slot v-for="item, i in dNavContentItems">
                <slot v-if="!Array.isArray(item)">
                    <d-btn :class="{active: dNavContentItemsId[i] === activeId }"
                           class="nav-content-item"
                           d-style="link-2"
                           :href="'#' + dNavContentItemsId[i]"
                           @click="goToAnchor(dNavContentItemsId[i], $event)">{{ item }}</d-btn>
                </slot>
                <slot v-else>
                    <slot v-for="itemInner, j in item">
                            <d-btn :class="{active: dNavContentItemsId[i][j] === activeId }"
                                   class="nav-content-item nav-content-item--sub"
                                   d-style="link-2"
                                   :href="'#' + dNavContentItemsId[i][j]"
                                   @click="goToAnchor(dNavContentItemsId[i][j], $event)">{{ itemInner }}</d-btn>
                    </slot>
                </slot>
            </slot>
        </nav>
    </div>
</template>

<script setup>
    import {
        ref
    } from 'vue'
    import {
        useEventListener
    } from 'dsrc/assets/js/event'
    import DBtn from '@/DBtn/DBtn'

    const activeId = ref('')

    const props = defineProps({
        dNavContentItems: Array,
        dNavContentItemsId: Array
    })
    const nav = ['/get-started', '/documentation', '/demos']

    function goToAnchor(id, e) {
        setTimeout(function() {
            const offset = document.getElementById(id).offsetTop
            const offsetHeader = document.querySelector('.header').offsetHeight
            window.scrollTo(0, offset - offsetHeader - 16);
        }, 0)
    }

    function hash() {
        return location.hash;
    }

    function initActiveId() {
        const titles = document.querySelectorAll('.title[id]')
        let offsets = []
        titles.forEach(function(title) {
            offsets.push(title.offsetTop)
        })

        offsets = offsets.map(function(offset) {
            return Math.abs(offset - (document.querySelector('.header').offsetHeight) - window.scrollY)
        })

        activeId.value = titles[offsets.indexOf(Math.min(...offsets))].getAttribute('id')
    }

    window.addEventListener('DOMContentLoaded', function(e) {
        initActiveId();
    })

    useEventListener(window, 'scroll', () => {
        initActiveId();
    })
</script>

<style lang="scss">
    @import './style.scss';
</style>
