<template>
    <section class="container-wrap">
        <div class="container flex">
            <d-nav-content :dNavContentItems="navContentItems"
                           :dNavContentItemsId="navContentItemsId"></d-nav-content>
            <div class="styled">
                <d-title d-level="1">Documentation</d-title>

                <d-title :id="navContentItemsId[0]">{{ navContentItems[0] }}</d-title>
                <highlightjs language="xml"
                             code='<div class="dreadmore">
    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Impedit recusandae quas eaque
    laudantiumquo, dolorem vitae quia cupiditate sit, exercitationem suscipit molestiae iste
    dolores eos facere mollitia voluptatibus nihil. Dicta ex blanditiis officiis beatae
    similique neque nostrum consequatur maiores quas!
</div>
<button type="button"
        data-drm-toggler>Show more</button>' />

                <d-title :id="navContentItemsId[1]">{{ navContentItems[1] }}</d-title>
                <p>new DReadMore(drmContainer, parameters) - initialize DReadMore with options:</p>
                <ul>
                    <li><b>drmContainer</b> - HTMLElement or string (with CSS Selector) of DReadMore container HTML element. Optional.</li>
                    <li><b>parameters</b> - object - object with DReadMore parameters. Optional.</li>
                    <li><b>Method returns initialized DReadMore instance.</b></li>
                </ul>
                <highlightjs language="js"
                             code="const dreadmore = new DReadMore('.dreadmore', {
    moreText: 'Show more',
    lessText: 'Up'
});" />

                <d-title :id="navContentItemsId[2]">{{ navContentItems[2] }}</d-title>
                <div class="table-wrap">
                    <table class="table">
                        <thead>
                            <tr>
                                <th class="th--width_0">
                                    Partameter
                                </th>
                                <th class="th--width_0">
                                    Type
                                </th>
                                <th class="th--width_0">
                                    Default
                                </th>
                                <th>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="tableItem in propertiesData"
                                :key="tableItem">
                                <td>

                                    <div class="hljs-params">
                                        {{ tableItem[0] }}

                                        <sup v-if="tableItem[4]"
                                              class="table__td--new">
                                            new, v2.2.0
                                        </sup>
                                    </div>
                                </td>
                                <td>
                                    <div class="hljs-variable language_">
                                        {{ tableItem[1] }}
                                    </div>
                                </td>
                                <td>
                                    <div class="hljs-string"
                                         v-html="tableItem[2]"></div>
                                </td>
                                <td class="styled">
                                    <div class="hljs-comment"
                                         v-html="tableItem[3]"></div>
                                    <template v-if="tableItem[0] === 'toggler'">
                                        <highlightjs language="js"
                                                     code="const dreadmore = new DReadMore({
    toggler: 'drmKeyToggler'
});" />
                                        <highlightjs language="html"
                                                     code='<div class="dreadmore">
    Lorem ipsum dolor sit amet consectetur
    adipisicing elit. Aspernatur laboriosam
    nobis incidunt quos repellat totam itaque!
</div>

<button type="button"
        data-drm-toggler="drmKeyToggler">
    Show more
</button>' />
                                    </template>
                                    <highlightjs v-if="tableItem[0] === 'beforeToggle'"
                                                 language="js"
                                                 code="const dreadmore = new DReadMore({
    beforeToggle: function($element, expanded) {
        console.log($element, expanded)
    }
});" />
                                    <highlightjs v-if="tableItem[0] === 'afterToggle'"
                                                 language="js"
                                                 code="const dreadmore = new DReadMore({
    afterToggle: function($element, expanded) {
        console.log($element, expanded)
    }
});" />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <d-title :id="navContentItemsId[3]">{{ navContentItems[3] }}</d-title>
                <div class="table-wrap">
                    <table class="table">
                        <thead>
                            <tr>
                                <th class="th--width_0">
                                    Method
                                </th>
                                <th>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="tableItem, i in methodsData"
                                :key="tableItem">
                                <td>
                                    <div class="hljs-params">
                                        {{ tableItem[0] }}
                                    </div>
                                </td>
                                <td class="styled">
                                    <div v-if="tableItem[1]"
                                         class="hljs-comment"
                                         v-html="tableItem[1]"></div>
                                    <template v-if="i === 0">
                                        <highlightjs language="js"
                                                     code="dreadmore.destroy();" />
                                        <highlightjs language="js"
                                                     code="dreadmore[1].destroy();" />
                                    </template>
                                    <template v-else-if="i === 1">
                                        <highlightjs language="js"
                                                     code="dreadmore.update();" />
                                        <highlightjs language="js"
                                                     code="window.addEventListener('resize', function() {
    dreadmore.forEach(function(item) {
        item.update();
    });
});" />
                                    </template>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <d-title :id="navContentItemsId[4]">{{ navContentItems[4] }}</d-title>
                <p>Aaaaaand you can disable in CSS :D</p>
                <highlightjs language="css"
                             code=".dreadmore {
    min-height: 0px;
}" />
            </div>
        </div>
    </section>
</template>

<script setup>
    import DTitle from '@/DTitle/DTitle'
    import DBtn from '@/DBtn/DBtn'
    import DNavContent from '^/DNavContent/DNavContent'

    const propertiesData = [
        ['initialExpand', 'Boolean', 'false', 'Set to <b>true</b> and the text will be expanded at the start'],
        ['isInitialExpandWhenUpdate', 'Boolean', 'false', 'Returns in the original state specified in startOpen when the screen is resized'],
        ['toggler', 'String', '', 'The key that is specified in the button attribute (data-drm-toggler)', true],
        ['moreText', 'String', '\'Show&nbsp;more\'', 'The text of the button when the collapsed text'],
        ['lessText', 'String', '\'Close\'', 'The text of the button when the expanded text'],
        ['beforeToggle', 'Function', '', 'beforeToggle called after a more or less link is clicked, but before the block is collapsed or expanded'],
        ['afterToggle', 'Function', '', 'afterToggle called after the block is collapsed or expanded']
    ]

    const methodsData = [
        ['dreadmore.destroy()', 'Destroy DReadMore instance and detach all events listeners'],
        ['dreadmore.update()'],
    ]

    const navContentItems = ['DReadMore HTML Layout', 'Initialize DReadMore', 'Parameters', 'Methods', 'Additionally']
    const navContentItemsId = []

    function stringToId(string) {
        return string.toLowerCase().replaceAll('?', '').replaceAll(' ', '-')
    }

    navContentItems.forEach(function(item) {
        if (!Array.isArray(item)) {
            navContentItemsId.push(stringToId(item));
        } else {
            let array = [];

            item.forEach(function(itemInner) {
                array.push(stringToId(itemInner))
            })

            navContentItemsId.push(array);
        }
    })
</script>
