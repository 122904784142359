import {
    createRouter,
    createWebHistory
} from 'vue-router'
import Home from '../views/Home'
import GetStarted from '../views/GetStarted'
import Documentation from '../views/Documentation'
import About from '../views/About'

const routes = [{
    path: '/',
    name: '',
    component: Home
}, {
    path: '/get-started',
    name: 'Get Started',
    component: GetStarted
}, {
    path: '/documentation',
    name: 'Documentation',
    component: Documentation
}, {
    path: '/demos',
    name: 'Demos',
    component: About
}, {
    path: '/:pathMatch(.*)*',
    name: 'DReadMore',
    component: Home
}]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    linkActiveClass: 'active',
    linkExactActiveClass: 'active',
    routes
})
router.beforeEach((to, from, next) => {
    document.title = `${ process.env.VUE_APP_TITLE } ${to.name}`
    next()
})

export default router
