<template>
    <div class="demo">
        <div class="dreadmore">
            <slot />
        </div>
        <d-btn d-style="link-3"
               data-drm-toggler>Show more</d-btn>
    </div>
</template>

<script setup>
    import DBtn from '@/DBtn/DBtn'
    import DReadMore from 'dreadmore';

	let interval = null;

    setTimeout(function() {
        const dReadMore = new DReadMore()
        const demo = document.querySelector('.demo')
        let width = demo.getBoundingClientRect().width

        interval = setInterval(() => {
            if (width !== demo.getBoundingClientRect().width) {
                width = demo.getBoundingClientRect().width

                dReadMore.update();
            }
        }, 1000);
    }, 0)
</script>

<style lang="scss">
    @import './style.scss';
</style>
