<template>
    <div class="nav-toggle"
         :class="{active: isActiveToggle}"
         @click="toggle">
        <span></span>
    </div>
    <div class="nav-wrap"
         ref="navWrap"
         @click="toggle">
        <nav class="nav"
             ref="nav">
            <slot v-for="navItem in navData">
                <router-link :to="navItem"
                             v-slot="{ navigate, href, route, isActive }"
                             custom>
                    <d-btn :class="{active: isActive}"
                           d-style="link-2"
                           :href="href"
                           @click="navigate">{{ route.name }}</d-btn>
                </router-link>
            </slot>
            <d-btn d-style="link-2"
                   href="https://github.com/DSekon/DReadMore"
                   target="_blank"
                   d-icon-name="github">GitHub</d-btn>
            <d-btn d-style="link-2"
                   href="https://www.donationalerts.com/r/dsekon"
                   target="_blank"
                   d-icon-name="donationalerts">Support</d-btn>
        </nav>
    </div>
</template>

<script setup>
    import {
        ref
    } from 'vue'
    import {
        useEventListener
    } from 'dsrc/assets/js/event'
    import DBtn from '@/DBtn/DBtn'

    // const navData = ['/get-started', '/documentation', '/demos']
    const navData = ['/get-started', '/documentation']
    const navWrap = ref(null)
    const nav = ref(null)
    const isActiveToggle = ref(false);

    function toggle() {
        if (window.innerWidth <= 767) {
            isActiveToggle.value = !isActiveToggle.value

            navWrap.value.style.height = isActiveToggle.value ? `${nav.value.clientHeight}px` : ''
        }
    }

    useEventListener(window, 'resize', () => {
        if (window.innerWidth > 767 && isActiveToggle.value) {
            isActiveToggle.value = false
            navWrap.value.style.height = ''
        }
    })
</script>

<style lang="scss">
    @import './style.scss';
</style>
