import {
    createApp
} from 'vue'
import './assets/scss/index.scss'
import App from './App'
import './registerServiceWorker'
import router from './router'
import store from './store'
import directiveRipple from './directive/ripple'

import hljs from 'highlight.js/lib/core';
import javascript from 'highlight.js/lib/languages/javascript';
import xml from 'highlight.js/lib/languages/xml';
import css from 'highlight.js/lib/languages/css';
import hljsVuePlugin from "@highlightjs/vue-plugin";

hljs.registerLanguage('javascript', javascript);
hljs.registerLanguage('xml', xml);
hljs.registerLanguage('css', css);

window.addEventListener('DOMContentLoaded', function(e) {
    if (location.hash) {
        setTimeout(function() {
            const offset = document.getElementById(location.hash.replace('#', '')).offsetTop
            window.scroll(0, offset - (document.querySelector('.header').offsetHeight + 16));
        }, 0)
    }
})

createApp(App)
    .use(store)
    .use(router)
    .use(hljsVuePlugin)
    .directive('ripple', directiveRipple)
    .mount('#app')
