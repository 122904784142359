<template>
    <div class="container-wrap main">
        <!-- <div class="main__bg">DReadMore</div> -->
        <div class="container main__container">
            <d-readmore>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Obcaecati consectetur distinctio atque blanditiis saepe dolore incidunt suscipit similique nulla tempore voluptatibus ullam ipsum, asperiores quas dicta architecto aperiam aliquid praesentium modi eum! Sint explicabo tempore voluptates est pariatur saepe expedita repudiandae voluptatem corporis neque assumenda ducimus ea, unde minus. Rem, ducimus? Neque error sint distinctio, quam quibusdam corporis quis ullam fuga? Dignissimos, ab impedit non at vel fugiat corporis et facilis accusamus atque! Cumque, quas nulla doloribus error molestias amet, ipsam maxime suscipit eius repellat sed sapiente minima labore facere esse consequatur quia libero neque eos velit molestiae reiciendis illo?
            </d-readmore>
            <div class="container main-github">
                <d-btn href="https://github.com/DSekon/DReadMore"
                       d-icon-name="github">View on GitHub</d-btn>
                <!-- {{ data.stars }} stars -->
                <p v-if="githubData && githubDataReleases"
                   class="main-github__info">{{ data.license_name }}, {{ data.version }} released on {{ data.date_released }}</p>
            </div>
        </div>
    </div>
</template>

<script setup>
    import {
        ref,
        reactive
    } from 'vue'
    import DBtn from '@/DBtn/DBtn'
    import DReadmore from '^/DReadmore/DReadmore'

    const data = reactive({});
    const githubData = ref(null);
    const githubDataReleases = ref(null);

    (async () => {
        const response = await fetch('https://api.github.com/repos/DSekon/DReadMore', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        githubData.value = await response.json();
        const response2 = await fetch('https://api.github.com/repos/DSekon/DReadMore/releases', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        githubDataReleases.value = await response2.json();

        data.stars = githubData.value.stargazers_count
        data.license_name = githubData.value.license.name
        data.version = githubDataReleases.value[0].name
        data.date_released = new Date(githubDataReleases.value[0].published_at).toLocaleDateString('en-US', {
            month: 'long',
            day: 'numeric',
            year: 'numeric'
        })
    })()
</script>

<style lang="scss">
    @import './style.scss';
</style>
