<template>
    <span class="icon" :class="dClass">
        <ic />
    </span>
</template>

<script setup>
    import {
        defineAsyncComponent
    } from 'vue'

    const props = defineProps({
        dName: String,
        dClass: {
            type: [String, Boolean],
            default: false
        }
    })

    const ic = defineAsyncComponent(() =>
        import(`#/${props.dName}`)
    )
</script>

<style lang="scss">
    @import './style.scss';
</style>
